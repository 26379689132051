import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { evaluatedDebts } from '../model/shared-component.interface';
import { HelpersService } from './helpers.service';

@Injectable({
  providedIn: 'root'
})

export class ChargesService {
  baseUrl = `${environment.apiUrl}`;
  constructor(private readonly http: HttpClient, private readonly helpersService: HelpersService) { }

  show(id): any {
    return this.http.get(`${this.baseUrl}/charges/${id}`);
  }

  refund(id, params): any {
    return this.http.post(`${this.baseUrl}/charges/${id}/refund`, params);
  }

  getClientCharges(params, filters?): any {
    let id = 0;
    if (filters) {
      id = filters.id_clients;
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/client/${id}/getClientChargesDataTable`, params);
  }

  getChargeRefunds(params, filters?): any {
    let id = 0;
    if (filters) {
      id = filters.id_clients;
    }

    return this.http.post(`${this.baseUrl}/charge/${id}/refunds`, params);
  }

  /**
   * createManualCharge
   * @param params 
   * id_clients: id_del_cliente, 
   * payment_types: id_del cargo(OXXO|SPEI|CARD), 
   * months_ahead(optional): numero de meses que se desean pagar por adelantado.
   * @returns 200 | Ok
   */
  createManualCharge(params): Observable<any> {
    return this.http.post(`${this.baseUrl}/charges/createManualCharge`, params);
  }

  /**
   * createFirstCharge
   * @param params
   * id_clients: id_del_cliente, 
   * payment_types: id_del cargo(OXXO|SPEI|CARD)
   * @returns 200 | Ok
   */
  createFirstCharge(params): Observable<any> {
    return this.http.post(`${this.baseUrl}/charges/createFirstCharge`, params);
  }

  /**
   * cancelLastCharge
   * @param params id_clients: for card, id_charges: for references, type: type of charge to unlock
   * @returns 200 | 404
   */
  cancelLastCharge(params): Observable<any> {
    return this.http.put(`${this.baseUrl}/charges/cancelLastCharge`, params);
  }

  getRecentCharges(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/charges/getRecentCharges`, params);
  }

  /**
   * freeMonthlyFeee
   * Manda una petición POST para dar una mensualidad gratis al cliente.
   * @param params reason: string, id_clients: number
   * @returns 
   */
  freeMonthlyFee(params): Observable<any> {
    return this.http.post(`${this.baseUrl}/charges/freeMonthlyFee`, params);
  }
  /**
   * formatChargeData
   * Formatea un objecto devuelto por la API cuando se usan los endpoints para saber la información
   * del ultimo cargo con tarjeta o referencia activa para que pueda ser utilizado por la función
   * showChargeInfo
   * @param charge_object Objecto devuelto por la API al momento de verificar si el cliente tiene una
   * referencia o cargo con tarjeta
   * @returns formated_charge
   */
  formatChargeData(charge_object): Object {
    const charge_data = charge_object.response.charge_data;
    const charge_extra_data = charge_object.response.charge_data.charge_extra_data;
    const formated_charge = {
      id_charges: charge_data.charge_data.id_charges,
      id_payment_type: charge_data.charge_data.id_payment_type,
      description: charge_data.charge_data.description,
      amount: charge_data.charge_data.amount,
      remaining_time: charge_data.remaining_time,
      time_name: charge_data.time_name,
      created_at: charge_data.charge_data.created_at
    };

    if (formated_charge.id_payment_type === 4 || formated_charge.id_payment_type === 5) {
      const extra_data = {
        extra_data: {
          reference: charge_extra_data.reference,
          type: charge_extra_data.type
        }
      };

      Object.assign(formated_charge, extra_data);
    }

    return formated_charge;
  }

  /**
   * CalculateMonthsAheadAmount
   * Calcula el total de las mensualidades que se quieren pagar por adelantado.
   * @param monthlyFee number mensualidad del cliente
   * @param months number meses que se pagaran por adelantado
   * @returns number total del numero de meses por adelantado
   */
  calculateMonthsAheadAmount(monthlyFee: number, months: number): number {

    return monthlyFee * months;

  }

  /**
   * formatMessage
   * Genera un objeto con la info que mostrara el modal cuando se reciba la confirmación de la creación de una referencia en la API
   * esta info se mostrara mientras la referencia no expire.
   * @param resp respuesta devuelta por la API con la info de la referencia creada
   * @param paymentType tipo de referencia que se generara OXXO/SPEI
   * @returns objeto que el titulo y el mensaje que llevara el modal
   */

  formatMessage(resp, paymentType): any {
    let offlineMsg = '<div>';
    let titleOffline = `Referencia ${paymentType} generada exitosamente`;
    const expirationDateText = 'Fecha de expiración:'
    const paymentInfo = resp.response.data.charges.data[0].payment_method;
    const amount = Math.round(resp.response.data.amount) / 100
    const expirationDate = moment(paymentInfo.expires_at * 1000).format('MMMM Do YYYY, h:mm:ss a');
    const paymentTypeOptions = {
      'OXXO': paymentInfo.reference,
      'SPEI': paymentInfo.clabe
    };
    const paymentTypeData = paymentTypeOptions[paymentType];
    let copyText = `Referencia: ${paymentTypeData}\nCantidad: ${amount}\n${expirationDateText} ${expirationDate}`;
    copyText += `\n*ESTA REFERENCIA ES UNICA Y TIENE UNA VIGENCIA DE 48 HORAS*\n*SI EL MONTO CAMBIA O PASAN 48 HORAS TENDRA QUE SOLICITAR OTRA REFERENCIA*`;
    offlineMsg += `<a>${paymentTypeData}<\a>`;
    offlineMsg += '<br> <br>';
    offlineMsg += `<a><strong>Cantidad: </strong>${amount}</a>`;
    offlineMsg += '<br>';
    offlineMsg += `<a><strong>${expirationDateText} </strong></a>`;
    offlineMsg += `<a>${expirationDate}<\a>`;
    offlineMsg += '<\div>';

    this.helpersService.copyToClipboard(copyText);

    return { title: titleOffline, msg: offlineMsg, copyText };
  }

  /**
   * calculateTotalForManual
   * Calcula el total de una referencia generada manualmente por Staff.
   */
  calculateTotalForManual(params): number {
    const monthsAhead = params.months_ahead;
    let total = 0;
    switch (params.idChargeType) {
      case 6:
        params.accounts.forEach(account => {
          total += account.fixed_monthly_fee;
        });
        total =  monthsAhead && monthsAhead > 0 ? total * monthsAhead : total;
        break;
      default:
        total;
        break;
    }
    
    return total;
  }

  /**
   * evaluateDebts
   * @param pendingDebts array de deudas del cliente 
   * @returns @interface evaluatedDebts
   */
  evaluateDebts(pendingDebts: Array<any>): evaluatedDebts {
    let totalDebt = 0;
    const idChargeType = pendingDebts.length === 1 ? 4 : 5;
    if (pendingDebts.length > 0) {
      pendingDebts.forEach(debt => {
        totalDebt += Number(debt.amount) + Number(debt.moratory_fees) + Number(debt.collection_fees);
      });
    }

    totalDebt = totalDebt / 100;

    return { idChargeType, totalDebt };
  }
}
